import { AfterContentChecked, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { constantStrings, POSITION, ROLE } from '../../helpers/constants';
import { AuthService } from '../../services/auth.service';
import { SharedStateService } from '../../services/shared-state.service';

@Component({
  selector: 'app-side-menu-options',
  templateUrl: './side-menu-options.component.html',
  styleUrls: ['./side-menu-options.component.scss']
})
export class SideMenuOptionsComponent implements OnInit, AfterContentChecked {

  @Output() isErrorFlag: EventEmitter<number> = new EventEmitter<number>();
  public currentUrl = '';
  public uploadfilesTabActive = false;
  public filedashboardTabActive = false;
  public historyTabActive = false;
  public settingsTabActive = false;
  public auditlogTabActive = false;
  public managefacilitiesTabActive = false;
  public uploadStatusTabActive = false;
  public loggedInUser: string;
  public sideMenuLocalized: any
  public isAdmin = false;
  public isTech = false;
  public isSurgeon = false;
  public isSupport = false
  public group: string;
  public isNurse: boolean;
  public isHAdmin: boolean;
  public isSnnManager: boolean;
  public isSnnSupport: boolean;
  public isStaff: boolean;
  public position: string;
  public userName: string;
  isError;
  isErrorDashboard: boolean = false;
  errorMessage: string;
  choosesiteTabActive: boolean;
  helpscreenTabActive: boolean;

  receiveValue($isErrorFlag) {
    this.isError = $isErrorFlag;
  }

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private sharedState: SharedStateService
  ) {
    this.translateService.setDefaultLang(constantStrings['defaultLanguage']);
    this.translateService.get(['sideMenu'])
      .subscribe((data) => {
        this.sideMenuLocalized = data.sideMenu;
      });

    if(this.authService.getItem('clpSession')){
      try{
      const idToken = this.authService.getCognitoAccessTokenFromStorage();
      const decodedValue = this.authService.decode(idToken);
      this.group = decodedValue ? decodedValue['cognito:groups'][0] : '';
      if(decodedValue['okta:olduserId']){
        this.userName = decodedValue['okta:olduserId'];
      }else{
        this.userName = decodedValue['cognito:username'];  
      }
    } catch (err) {
      //Error message from api
      this.errorMessage = err.message;
    }
    }
    if(this.group){
      if (this.group.toLowerCase() === ROLE.IC_ADMIN) {
        this.isAdmin = true;
        this.position = POSITION.IC_ADMIN;
      }
      if (this.group.toLowerCase() === ROLE.IC_USER) {
        this.isTech = true;
        this.position = POSITION.IC_USER;
      }
      if (this.group.toLowerCase() === ROLE.NURSE) {
        this.isNurse = true;
        this.position = POSITION.NURSE;
      }
      if (this.group.toLowerCase() === ROLE.SURGEON) {
        this.isSurgeon = true;
        this.position = POSITION.SURGEON;
      }
      if (this.group.toLowerCase() === ROLE.H_ADMIN) {
        this.isHAdmin = true;
        this.position = POSITION.H_ADMIN;
      }
      if (this.group.toLowerCase() === ROLE.SNN_HIL_MANAGER) {
        this.isSnnManager = true;
        this.position = POSITION.SNN_HIL_MANAGER;
      }
      if (this.group.toLowerCase() === ROLE.SNN_CLOUD_SUPPORT) {
        this.isSnnSupport = true;
        this.position = POSITION.SNN_CLOUD_SUPPORT;
      }
      if (this.group.toLowerCase() === ROLE.STAFF) {
        this.isStaff = true;
        this.position = POSITION.STAFF;
      }
    }

    //Navigating based on the options selected
    if (this.router) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
          this.choosesiteTabActive = this.currentUrl.includes('/choosesite') ? true : false;
          this.uploadfilesTabActive = this.currentUrl.includes('/uploadfiles') ? true : false;
          this.filedashboardTabActive = this.currentUrl.includes('/filedashboard') ? true : false;
          this.historyTabActive = this.currentUrl.includes('/history') || false;
          this.settingsTabActive = this.currentUrl.includes('/settings') || false;
          this.auditlogTabActive = this.currentUrl.includes('/auditlog') || false;
          if(this.group && (this.group.toLowerCase() === ROLE.SNN_HIL_MANAGER)){
            this.managefacilitiesTabActive = this.currentUrl.includes('/managefacilities') || false;
          }else{
            this.managefacilitiesTabActive = this.currentUrl.includes('/managefacilities/facilitylinking') || false;
          }
          this.uploadStatusTabActive = this.currentUrl.includes('/uploadstatus') || false;
          this.helpscreenTabActive = this.currentUrl.includes('/helpcenter') || false;
          //When clicked on Upload status Tab error notification dissapears
          if (this.uploadStatusTabActive) {
            this.authService.removeItem('isError');
          }
          if(this.helpscreenTabActive){
            let notification= {
              isHelpScreen:true
            }
            this.sharedState.setDashboardStateData(notification);
          }
        }
      });
    }
  }

  ngOnInit(): void {

  }

  ngAfterContentChecked(): void {
    //get error flag value from local storage for upload status
    this.isError = localStorage.getItem('isError');
    //get error flag value from local storage for file dashboard
    let isErrorDb = this.authService.getItem('isErrorDashboard');
    if(isErrorDb && isErrorDb.isErrorDashboard){
      this.isErrorDashboard = isErrorDb.isErrorDashboard;
    } else {
      this.isErrorDashboard = false;
    }
  }

  showUploadfile(){
    let notification = {
      displayUploadFile: true,
      displaySiteModal: false,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
  }

}
