import { Observable, of } from 'rxjs';

import { AbstractAction } from './action.abstract';
import { ActionType } from './action.type';

/**
 * Action is basic that consists of icon and title
 */
export class BaseAction extends AbstractAction {
  // It is responsible for availability
  private _enable: Observable<boolean>;
  private _forceDisable: boolean;

  constructor(public icon: string, public title: string) {
    super();
    this._enable = of(true);
    this._forceDisable = false;
  }

  /**
   * @method setEnable
   * Change avalability of action
   * @param to the state is to be set
   */
  setDisableForce(force_disable: boolean): void {
    this._forceDisable = force_disable;
  }

  /**
   * @method isEnable
   * @returns true if the action is available
   */
  isEnable(): Observable<boolean> {
    if (this._forceDisable) {
      return of(false);
    }

    return this.callbackEnable();
  }

  /**
   * @method callbackEnable
   * Callback function that can be override
   * @returns
   */
  callbackEnable(): Observable<boolean> {
    return this._enable;
  }

  /**
   * @method type
   * @returns action type
   */
  type(): ActionType {
    return ActionType.BASE;
  }
}
