import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { INITIAL_MODAL_STATE, Modal } from 'src/app/shared/models/dialog.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { DatePipe } from '@angular/common';
import { FileDashboardService } from '../filedashboard.service';
import { constantStrings } from '../../shared/helpers/constants'
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-uploadincomplte-dialog',
  templateUrl: './uploadincomplte-dialog.component.html',
  styleUrls: ['./uploadincomplte-dialog.component.scss']
})
export class UploadIncompleteComponent implements OnInit, OnDestroy {
  @Input() modal: Modal = INITIAL_MODAL_STATE;
  @Input() fileData;
  @Output() event = new EventEmitter<boolean>();
  selectedDashboardItem: any;
  errRecallDetails: any;
  recallDetailsRes: any;
  isRecalled: boolean;
  displayFacility: string;
  displaySurgeon: string;
  displayApplication: string;
  anotomySide = "";
  patientFirstName = '';
  patientLastName = '';
  today: Date = new Date();
  selectedDob: Date;
  increase = 1;
  increaseElement = [];
  isDisabled = true;
  missingTags = {};
  elementObj = {};
  displayDob = constantStrings.displayDOB;
  missingTagModal: Modal = INITIAL_MODAL_STATE;
  updateSubscription: Subscription;

  constructor(public router: Router,
    private sharedState: SharedStateService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private fileDashboardService: FileDashboardService,
    private datePipe: DatePipe
  ) {
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      //this.auditAction = response;
      //   console.log(response);
    });
    //Recieving loggedIn user details from local storage 
    if (this.authService.getItem('userDetails')) {
      //this.userInfo = this.authService.getItem('userDetails');
    }
  }

  ngOnInit() {
    if (!this.fileData?.patientFirstName)
      this.missingTags['fname'] = false;
    if (!this.fileData?.patientLastName)
      this.missingTags['lname'] = false;
    if (!this.fileData?.patientDOB)
      this.missingTags['dob'] = false;
    if (!this.fileData?.studyDescription)
      this.missingTags['studyDesc'] = false;
  }

  public onCancel(isCancelButton): void {
    if (isCancelButton === true) {
      this.event.emit(false);
    }
    else {
      this.event.emit(this.fileData?.uploadInCompleteEditable == 2 ? true : false);
    }
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
  }

  /**
   * (description): function call to confirm recall
   * (memberof) : ConfirmRecallPopUpComponent
   */
  public onConfirm(): void {
    this.isDisabled=true;
    let params = { 'uploadId': this.fileData.uploadId };
    let tags = [];
    if (!this.fileData?.patientFirstName)
      tags.push({ "tagId": "patientFirstName", "tagValue": this.patientFirstName });
    if (!this.fileData?.patientLastName)
      tags.push({ "tagId": "patientLastName", "tagValue": this.patientLastName });
    if (!this.fileData?.patientDOB)
      tags.push({
        "tagId": "patientDOB", "tagValue": ('0' + (this.selectedDob.getMonth() + 1)).slice(-2) + '/' + ('0' + this.selectedDob.getDate()).slice(-2) + '/' + this.selectedDob.getFullYear()
      });
    if (!this.fileData?.studyDescription)
      tags.push({ "tagId": "studyDescription", "tagValue": this.anotomySide });
    params['tags'] = tags;
    this.updateSubscription = this.fileDashboardService.postUpdateMissingTags(params).subscribe((res) => {
      if (res) {
        if (res.toLowerCase() == constantStrings.SUCCESS) {
          this.fileDashboardService.isMissingTagUpdateFailed = false;
        }
        else {
          this.fileDashboardService.isMissingTagUpdateFailed = true;
        }
        this.fileDashboardService.missingTagResponseMsg = res.toLowerCase();
        this.onCancel(false);
      }
    }, (error) => {
      this.fileDashboardService.isMissingTagUpdateFailed = true;
      this.onCancel(false);
      this.fileDashboardService.missingTagResponseMsg = constantStrings.ERROR;
    })
  }

  validateFields(event, field) {
    if (!this.fileData?.patientFirstName && field == constantStrings.firstName) {
      if (event.target.value.trim() !== '') {
        this.elementObj['fname'] = true;
        this.missingTags['fname'] = true;
      } else {
        this.elementObj['fname'] = false;
      }
    }

    if (!this.fileData?.patientLastName && field == constantStrings.lastName) {
      if (event.target.value.trim() !== '') {
        this.elementObj['lname'] = true;
        this.missingTags['lname'] = true;
      } else {
        this.elementObj['lname'] = false;
      }
    }
    if (!this.fileData?.patientDOB && field == constantStrings.dateOfBirth) {
      if (this.selectedDob) {
        this.elementObj['dob'] = true;
        this.missingTags['dob'] = true;
      } else {
        this.elementObj['dob'] = false;
      }
    }
    if (!this.fileData?.studyDescription && field.toLowerCase() == constantStrings.studyDesc) {
      if (this.anotomySide) {
        this.elementObj['studyDesc'] = true;
        this.missingTags['studyDesc'] = true;
      } else {
        this.elementObj['studyDesc'] = false;
      }
    }
    if (Object.values(this.missingTags).every(item => item)) {
      if (Object.values(this.elementObj).every(item => item)) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    }
  }

  dateSelection(event: Date) {
    this.selectedDob = event;
    this.displayDob = this.datePipe.transform(this.selectedDob, constantStrings.dobFormat);
    this.validateFields(event, constantStrings.dateOfBirth);
  }

  getCount(element) {
    if (!this.increaseElement.includes(element) && element === constantStrings.firstName) {
      this.increaseElement.push({ 'fname': this.increase++ });
    } else if (!this.increaseElement.includes(element) && element === constantStrings.lastName) {
      this.increaseElement.push({ 'lname': this.increase++ });
    }
    else if (!this.increaseElement.includes(element) && element === constantStrings.dateOfBirth) {
      this.increaseElement.push({ 'dob': this.increase++ });
    }
    else if (!this.increaseElement.includes(element) && element === constantStrings.studyDesc) {
      this.increaseElement.push({ 'studydesc': this.increase++ });
    }
    const tempVal = this.increaseElement.find(x => x.hasOwnProperty(element));
    return tempVal[element];
  }

  ngOnDestroy() {
    this.patientFirstName = '';
    this.patientLastName = '';
    this.selectedDob = undefined;
    this.anotomySide = '';
    this.updateSubscription?.unsubscribe();
  }

  checkSection(){
    let val=false;
    if (!this.fileData?.patientFirstName || !this.fileData?.patientLastName || !this.fileData?.studyDescription){
      if(!this.fileData?.patientDOB)
      val=true;
      else
      val=false;
    }
    return val;
  }
}
