import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


// import { KeyInteractionService } from 'src/app/interactions/key-interaction.service';
import { AlertDialogData, AlertDialogResults } from './alert-dialog.data';
import { TriggerAction } from '../actions/action.trigger';
import { FormControl } from '@angular/forms';
/**
 * Component for confirmation dialog
 */
@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnDestroy {
  public rejectAction: TriggerAction;
  public confirmAction: TriggerAction;
  public text: string;
  public reject: string;
  public confirm: string;
  public message= null;
  public isHighlightForConfirm: boolean;

  private _unsubscribe$: Subject<void>;

  constructor(
    private _dialogRef: MatDialogRef<AlertDialogComponent>,
    // private _keyInteractionService: KeyInteractionService,
    @Inject(MAT_DIALOG_DATA) private _dialogData: AlertDialogData
  ) {
    // this._keyInteractionService.isDialogOpen = true;
    this._unsubscribe$ = new Subject<void>();
    this._dialogRef
      .keydownEvents()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((keyEvent: KeyboardEvent) => {
        if (keyEvent.key === 'Enter') {
          this._dialogRef.close({
            result: AlertDialogResults[1],
            data: this.message?.value,
          });
        }
      });

    this.text = this._dialogData?.text || 'Dialog.DEFAULT_MESSAGE';
    this.reject = this._dialogData?.reject || 'Dialog.RejectButtons.BACK';
    this.confirm = this._dialogData?.confirm;
    if (this._dialogData?.message) {
      this.message = new FormControl('');
    }
    this.isHighlightForConfirm = this._dialogData?.isHighlightForConfirm;

    this.rejectAction = new TriggerAction('', this.reject, () => {
      this._dialogRef.close({
        result: AlertDialogResults[0],
      });
    });
    this.confirmAction = new TriggerAction('', this.confirm, () => {
      this._dialogRef.close({
        result: AlertDialogResults[1],
        data: this.message?.value,
      });
    });
  }

  ngOnDestroy() {
    // this._keyInteractionService.isDialogOpen = false;
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  onClose() {
    this._dialogRef.close({
      result: AlertDialogResults[2],
    });
  }
}
