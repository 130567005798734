import { Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { INITIAL_MODAL_STATE, Modal } from 'src/app/shared/models/dialog.model';
import { FileDashboardService } from '../filedashboard.service';
import { constantStrings} from '../../shared/helpers/constants'
@Component({
  selector: 'app-missingtag-success-modal',
  templateUrl: './missingtag-success-modal.component.html',
  styleUrls: ['./missingtag-success-modal.component.scss']
})
export class MissingtagSuccessModalComponent implements OnDestroy  {

  @Input() modal: Modal =INITIAL_MODAL_STATE ;
  @Output() event=new EventEmitter();
  isMissingTagUpdateFailed=false;
  responseMsg='';
  constantInProgress='';
  constantSuccess='';
  constantFailed='';
  constantError='';

  constructor(private fileDashboardService: FileDashboardService) {
    this.isMissingTagUpdateFailed=fileDashboardService.isMissingTagUpdateFailed;
    this.responseMsg=this.fileDashboardService.missingTagResponseMsg;
    this.constantInProgress=constantStrings.inProgress;
    this.constantSuccess=constantStrings.SUCCESS;
    this.constantFailed=constantStrings.FAILED;
    this.constantError=constantStrings.ERROR;
  }
  
  onConfirm(event) {
    this.event.emit(event);
  }

  ngOnDestroy(): void {
    this.fileDashboardService.isMissingTagUpdateFailed=false;
    this.fileDashboardService.missingTagResponseMsg='';
  }

}

