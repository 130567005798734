import { Subject} from 'rxjs';
import { BaseAction } from './action.base';
import { ActionType } from './action.type';
import { takeUntil } from 'rxjs/operators';

/**
 * Action is callback trigger
 */
export class TriggerAction extends BaseAction {
  constructor(
    public icon: string,
    public title: string,
    private callback: (object?: any) => void
  ) {
    super(icon, title);
  }

  /**
   * @method trigger
   * Triggers the callback if it is available
   */
  trigger(object?: any): void {
    const triggerUnsubscribe$ = new Subject<void>();

    this.isEnable()
      .pipe(takeUntil(triggerUnsubscribe$))
      .subscribe((isEnabled) => {
        triggerUnsubscribe$.next();
        triggerUnsubscribe$.complete();
        isEnabled && this.callback(object);
      });
  }

  /**
   * @method type
   * @returns action type
   */
  type(): ActionType {
    return ActionType.TRIGGER;
  }

  /**
   * @method changeTitleOfAction
   */
  changeTitleOfAction(title: string): void {
    this.title = title;
  }
}
