import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import {
  MatDialogRef,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AlertDialogAnswer } from './alert-dialog.data';
import { AlertDialogComponent } from './alert-dialog.component';

/**
 * Service class to call confirmation dialog
 */
@Injectable({providedIn: 'root'})
export class AlertDialogService {
  constructor(private dialog: MatDialog, private translate: TranslateService) {}

  /**
   * Call the confirmation dialog
   * @param text the confirmation dialog text
   * @param reject the rejection button title
   * @param confirm the confirmation button title
   * @param message the confirmation dialog message for confirm option
   * @param isHighlightForConfirm the highlight for confirm button
   */
  public openConfirmDialog(
    text: string,
    reject: string,
    confirm: string = '',
    message: boolean = false,
    isHighlightForConfirm: boolean = true
  ): Observable<AlertDialogAnswer> {
    const confirmDialogConfig: MatDialogConfig = {
      panelClass: 'confirm-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    };

    confirmDialogConfig.data = {
      text: text,
      reject: reject,
      confirm: confirm,
      message: message,
      isHighlightForConfirm: isHighlightForConfirm,
    };

    const dialogRef: MatDialogRef<AlertDialogComponent> = this.dialog.open(
     AlertDialogComponent,
      confirmDialogConfig
    );

    return dialogRef.afterClosed();
  }
}
