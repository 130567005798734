import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ROLE } from '../shared/helpers/constants';
import { AuditActionData, environmentURL, errorResponse, LoggedUserDetails, settingsResponse } from '../shared/models/user.model';
import { AuthService } from '../shared/services/auth.service';
import { SharedStateService } from '../shared/services/shared-state.service';
import { UploadService } from '../uploadfiles/upload.service';

@Component({
  selector: 'app-exitapps',
  templateUrl: './exitapps.component.html',
  styleUrls: ['./exitapps.component.scss']
})
export class ExitappsComponent implements OnInit {
  isSurgeonTrue;
  userInfo: LoggedUserDetails;
  auditAction: AuditActionData;
  errUserDetails: errorResponse;
  actionActivityDetails: settingsResponse;
  baseState: string;
  baseEnv: environmentURL;
  constructor(
    private sharedState: SharedStateService,
    private authService: AuthService,
    private uploadService:UploadService
  ) { 
    const idToken = this.authService.getCognitoAccessTokenFromStorage();
    const decodedValue = this.authService.decode(idToken);
    this.baseState = decodedValue ? decodedValue['cognito:groups'][0] : '';
    if(this.baseState.toLowerCase() == ROLE.SNN_HIL_MANAGER.toLowerCase()){
      this.baseEnv = environment.snnClp
    } else {
      this.baseEnv = environment.clp
    }
  }

  ngOnInit(): void {
    //Recieving loggedIn user details from local storage 
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
    }
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
      if(this.userInfo && (this.auditAction && this.auditAction.UserActions)) {
        this.auditLogActivity();
      }
    });
    this.isSurgeonTrue={
      isDisplaySelectionfacility:false,
      isDisplaySelectionSurgeon:false,
      isDisplaySelectionApplication:false,
      isUploadFailed:false,
      isHelpScreen:false
    }
    this.sharedState.setDashboardStateData(this.isSurgeonTrue);
  }

  /**
   * (description): To call audit log activity function
   * (memberof) : Component
  **/
  auditLogActivity(){
    let action = {
      "userFacilityId" : this.userInfo.userFacilityId,
      "userName" : this.userInfo.userName,
      "action" : this.auditAction.UserActions.exitApps + this.userInfo.userName,
      "eventOutCome" : 1
    }
    this.uploadService.auditLogActivity(action).subscribe({
      next: result => {
        this.actionActivityDetails = result;
        this.authService.setItem('uploadFileStatus', null);
        this.authService.clear();
        this.authService.exitToApps();
      }, error: err => {
        this.errUserDetails = err;
        this.authService.setItem('uploadFileStatus', null);
        this.authService.clear();
        this.authService.exitToApps();
      }
    });
  }
}
