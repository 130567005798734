import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { constantStrings } from './shared/helpers/constants';

@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private contentHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded' 
      })   
    };
  private errMsg: any;
  private errObj: any;
  public serviceLocalized: any;
  directHttp : HttpClient;
  public statusCode: any = 400;
  public unauthorizedStatusCode: any = 401;

  constructor(private http: HttpClient, 
              private translateService: TranslateService,
              private handler: HttpBackend) {
    this.directHttp = new HttpClient(handler); 
    this.translateService.setDefaultLang('en');
    this.translateService.get(['serviceLocals']).subscribe((data) => {
        this.serviceLocalized = data.serviceLocals;
      });
   }
  /**
   * (description): To make GET http call so as to get error message
   * (returns) : observable
   * (memberof) : ApiHttpService
   */
  public get(url: string, options?: any) {
    return options ? this.http.get(url, options).pipe(catchError(errMsg =>this.handleError(errMsg))) :
                    this.http.get(url, this.httpOptions).pipe(catchError(errMsg =>this.handleError(errMsg)));
  }

  /**
   * (description): To make GET http call so as get error object
   * (returns) : observable
   * (memberof) : ApiHttpService
   */
  public getHttp(url: string, options?: any) {
    return options ? this.http.get(url, options).pipe(catchError(errObj =>this.handleErrorObj(errObj))) :
                    this.http.get(url, this.httpOptions).pipe(catchError(errObj =>this.handleErrorObj(errObj)));
  }

  /**
   * (description): To make POST http call
   * (memberof) : ApiHttpService
   * (returns) : observable
   */
  public post(url: string, data: any, options?: any) {
    return options ? this.http.post(url, data, options).pipe(catchError(errMsg =>this.handleError(errMsg))) :
                    this.http.post(url, data, this.httpOptions).pipe(catchError(errMsg =>this.handleError(errMsg)));
  }

   /**
   * (description): To make POST http call so as get error object
   * (returns) : observable
   * (memberof) : ApiHttpService
   */
  public postHttp(url: string, data: any, options?: any) {
    return options ? this.http.post(url, data, options).pipe(catchError(errObj =>this.handleError(errObj))) :
                    this.http.post(url, data).pipe(catchError(errObj =>this.handleErrorObj(errObj)));
  }

  /**
   * (description): To make PUT http call 
   * (memberof) : ApiHttpService
   * (returns) : observable
   */
  public put(url: string, data: any, options?: any) {
    return options ? this.http.put(url, data, options).pipe(catchError(errMsg =>this.handleError(errMsg))) :
                    this.http.put(url, data).pipe(catchError(errMsg =>this.handleError(errMsg)));
  }

  /**
   * (description): To make PUT http call 
   * (memberof) : ApiHttpService
   * (returns) : observable
   */
  public putHttp(url: string, data: any, options?: any) {
    return options ? this.http.put(url, data, options).pipe(catchError(errObj =>this.handleErrorObj(errObj))) :
                    this.http.put(url, data).pipe(catchError(errObj =>this.handleErrorObj(errObj)));
  }

  /**
   * (description): To make DELETE http call
   * (memberof) : ApiHttpService
   * (returns) : observable
   */
  public delete(url: string, options?: any, oktacall: any = null) {
    let optionsParam;
    if(oktacall){
      optionsParam = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': oktacall,
        }),
        body: '',
      }
    } else {
      optionsParam = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: options,
      }
    }
 
    return this.http.delete(url, optionsParam).pipe(catchError(errMsg =>this.handleError(errMsg)));
  }

  public request(requestName: string, url: string){
    return this.http.request(requestName, url).pipe(catchError(errMsg =>this.handleError(errMsg)));
  }

  public directHttpPost(url: string, data: any){
    return this.directHttp.post(url,data,this.contentHeaders).pipe(catchError(errMsg =>this.handleError(errMsg)));
  }

    /**
     * (description): To handle the error
     * (returns) : this will help to display the error message received from backend
     */
    private handleError(error: HttpErrorResponse): any {
      if (error.error instanceof ErrorEvent) {
        this.errMsg = 'An error occurred:' + error.error.message;
      } else {
        const statusCode = this.statusCode;
        if (error.status === statusCode) {
          this.errMsg = error.error.errorMessage;
        } else {
          this.errMsg = this.serviceLocalized.errorMessage;
        }
      }
      return throwError(this.errMsg);
    }

    /**
     * (description): To handle the error
     * (returns) : this will help to display the error object received from backend
     */
    private handleErrorObj(error: HttpErrorResponse): any {
      if (error.error instanceof ErrorEvent) {
        this.errObj = error.error;
      } else {
        const statusCode = this.statusCode;
        const unauthorizedStatusCode = this.unauthorizedStatusCode;
        if (error.status === statusCode || error.status === unauthorizedStatusCode) {
          this.errObj = error.error;
        } else {
          this.errObj = this.serviceLocalized.errorMessage;
        }
      }
      return throwError(this.errObj);
    }

}
