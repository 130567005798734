export const AlertDialogResults = ['REJECT', 'CONFIRM', false] as const;
export type AlertDialogResultsType = typeof AlertDialogResults[number];

export interface AlertDialogAnswer {
  result: AlertDialogResultsType;
  data: string | null;
}

export interface AlertDialogData {
  text: string;
  reject: string;
  confirm: string;
  message: boolean;
  isHighlightForConfirm: boolean;
}

export enum TextsForErrorsDialogs {
  CLOSE = 'ConnectionFailure.close',
  PROCESSING_ERROR_PLEASE_REFRESH = 'PROCESSING_ERROR_PLEASE_REFRESH',
  PROCESSING_ERROR_INVALID_FILE_TYPE = 'PROCESSING_ERROR_INVALID_FILE_TYPE',
}
