import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantStrings, statusCode } from 'src/app/shared/helpers/constants';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { UploadService } from 'src/app/uploadfiles/upload.service';
import { MatSort, Sort } from '@angular/material/sort';
import { HistoryDetailsModal, INITIAL_HistoryDetailsModal_STATE } from 'src/app/shared/models/dialog.model';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-batch-upload',
  templateUrl: './batch-upload.component.html',
  styleUrls: ['./batch-upload.component.scss']
})
export class BatchUploadComponent implements OnInit,OnDestroy {

  public addModalUi: HistoryDetailsModal = INITIAL_HistoryDetailsModal_STATE;
  batchUploadList=new MatTableDataSource();
  selection = new SelectionModel<any>(false, []);
  displayedColumns: string[] = ['createdDateDisplay', 'batchId', 'uploaderFirstName', 'surgeonFirstName', 'targetFacilityName', 'uploadStatus'];
  dataSource= new MatTableDataSource();
  isDisabled: boolean = false;
  selectedBatchId: string = '';
  viewDetailsFlag: boolean = false;
  userInfo: any;
  batchObject:any;
  pagelimit: number;
  searchText: string='';
  filterActive: boolean=false;
  errorRes: any;
  auditText: any;
  auditId: any;
  historyData: any;
  auditAction: any;
  actionActivityDetails: any;
  errUserDetails: any;
  storeBatchData: any;
  isReceived:boolean=false;
  pageName='';
  batchRecordSub:Subscription;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private uploadService: UploadService,
    private sharedState: SharedStateService,
    private datePipe: DatePipe,
    private _liveAnnouncer: LiveAnnouncer) { 
    if (this.authService.getItem('userDetails')) {
      this.pagelimit = statusCode.PageLimit;
      this.userInfo = this.authService.getItem('userDetails');
      this.batchObject = {
        linkedFacilityId: this.userInfo.userFacilityId,
        searchText:'',
        uploadId: [],
        uploaderId: [],
        surgeonId: [],
        targetFacilityId: [],
        uploadStatus: [statusCode.UPLOADPENDING,statusCode.FAILED],
        fromCreatedDate: null,
        toCreatedDate: null,
        page: 0,
        limit: this.pagelimit,
        column: constantStrings.createdDate
      }
      this.loadBatchDetails(this.batchObject);
    }
  }

  ngOnInit(): void {
     //this.batchUploadList=this.dataSource;
     //To get user actions from json file
     this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });

    this.batchRecordSub=this.uploadService.batchUploadSubject$.subscribe(res=>{
      if(res){
      this.batchObject=res;
      this.loadBatchDetails(res);
      }
    })
  }

  dataSelection(listItem) {
    this.selectedBatchId = listItem.batchId;
    this.isDisabled=true;
  }

  public viewDetails(): void {
    let notification = {
      uploadId: this.selectedBatchId
    }
    this.sharedState.historyDetails(notification);
    this.viewDetailsFlag = true;
    this.pageName=constantStrings.batchPage;
    this.addModalUi = {
      display: 'block',
      header: "",
      content: this.selectedBatchId,
      closeButton: "",
      closeFunction: () => { }
    };
  }

  loadBatchDetails(params){
    this.spinnerService.show();
    this.uploadService.getBatchDetails(params).subscribe({
      error: err => {
        //If API fails setting the historyCount to 0 for displaying error message
        this.isReceived = true;
        if(this.searchText){
          this.auditLogActivity(constantStrings.SEARCH);
        } 
        if(this.filterActive)       {
          this.auditLogActivity(constantStrings.FILTER);
        }
        this.errorRes = err;
        this.spinnerService.hide();
      },
      next:result => {
      if (result && result.historyDetails) {
          this.isReceived=true;
         this.historyData=result;
          for (let i = 0; i < result.historyDetails.length; i++) {
            const auditlogData =  result.historyDetails[i];
            const utcDatetime = auditlogData.createdDate;
            const utcDate = new Date(utcDatetime);        
            const createdDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000); // Convert to local timezone
            result.historyDetails[i].createdDate = createdDate;
            //Converting data object to string format to display as per figma
            let fixedDatetime = createdDate.toISOString();
            result.historyDetails[i]['createdDateDisplay'] = this.datePipe.transform(fixedDatetime, 'MM/dd/yyyy hh:mm:ss a');
          }
          if (this.batchObject.page == 0) {
            this.storeBatchData=[];
            this.storeBatchData = result.historyDetails;
          } else {
            this.storeBatchData = [...this.storeBatchData, ...result.historyDetails]
          }
         this.batchUploadList=new MatTableDataSource(this.storeBatchData);
         setTimeout(() => {
            this.batchUploadList.sort = this.sort;
          }, 100);
          if(this.searchText){
            this.auditLogActivity(constantStrings.SEARCH);
          } 
          if(this.filterActive)       {
            this.auditLogActivity(constantStrings.FILTER);
          }
          this.spinnerService.hide();
        }
        else{
          this.storeBatchData=[];
          this.batchUploadList=new MatTableDataSource(this.storeBatchData);
          this.spinnerService.hide();
        }
      }
    });
  }

  auditLogActivity(str) {
    if(str === constantStrings.SEARCH){
      this.auditText = this.auditAction.UserActions.searchLog;
      this.auditId = this.searchText;
    } else {
      this.auditText = this.auditAction.UserActions.filterLog;
      this.auditId = null;
    }
    let actionOutcome = {
      "userFacilityId": this.userInfo.userFacilityId,
      "userName": this.userInfo.userName,
      "action" : this.auditId ? this.auditText+''+this.auditId : this.auditText ,
      "eventOutCome" : this.historyData ? 1 : 0
    }
    this.uploadService.auditLogActivity(actionOutcome).subscribe({
      next: result => {
        this.actionActivityDetails = result;
      }, error: err => {
        this.errUserDetails = err;
      }
    });
  }

  ngAfterViewInit() {
    this.viewDetailsFlag = false;
    this.batchUploadList.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  ShowMore() {
    this.batchObject.page = this.batchObject.page + 1;
    this.loadBatchDetails(this.batchObject);
  }

  ngOnDestroy(): void {
    this.batchRecordSub?.unsubscribe();
    this.uploadService.batchUploadSubject.next(null);
  }

}
