import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AlertComponent } from './components/alert/alert.component';
import { UserManagementService } from '../authenticate/usermanagement.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { JwtInterceptor } from './services/jwtinterceptor.service';
import { AuthService } from './services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmModalComponent } from './components/confirmmodal/confirmmodal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoAlertComponent } from './components/infoalert/infoalert.component';
import { InfoAlertService } from './services/infoalert.service';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { SideMenuProfileComponent } from './components/side-menu-profile/side-menu-profile.component';
import { SideMenuFooterComponent } from './components/side-menu-footer/side-menu-footer.component';
import { SideMenuOptionsComponent } from './components/side-menu-options/side-menu-options.component';
import { SideMenuNotificationsComponent } from './components/side-menu-notifications/side-menu-notifications.component';
import { InfoPopUpComponent } from './components/info-pop-up/info-pop-up.component';
import { HelpScreenComponent } from './components/help-screen/help-screen.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { SidemenuColapseComponent } from './components/sidemenu-colapse/sidemenu-colapse.component';
import { ConnectionFailurePopUpComponent } from './components/connectionFailure-pop-up/connectionFailurepopup.component';
import { RouterModule } from '@angular/router';
import { AlertDialogComponent } from './components/alert-dialog';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/locales/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    PdfJsViewerModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    AlertComponent,
    ConfirmModalComponent,
    InfoAlertComponent,
    SidemenuComponent,
    InfoPopUpComponent,
    HelpScreenComponent,
    ConnectionFailurePopUpComponent,
    AlertDialogComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AlertComponent,
    ConfirmModalComponent,
    InfoAlertComponent,
    SidemenuComponent,
    SideMenuProfileComponent,
    SideMenuFooterComponent,
    SideMenuOptionsComponent,
    SideMenuNotificationsComponent,
    InfoPopUpComponent,
    HelpScreenComponent,
    SidemenuColapseComponent,
    ConnectionFailurePopUpComponent,
    AlertDialogComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    UserManagementService,
    AuthService,
    InfoAlertService
  ]
})
export class SharedModule { }
