import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { APINames, constantStrings, ROLE } from '../helpers/constants';
import { SharedStateService } from '../services/shared-state.service';
import { environmentURL } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})

export class ApiprocedureService {

    private readonly baseUrl: string = environment.baseUrl;
    private readonly baseUrlClp: string = environment.baseUrlClp;
    private readonly baseUrlClpSnn: string = environment.baseUrlClpSnn;
    private getFacilityAPI: string = this.baseUrl + '/imageupload/facilities';
    private getSurgeonAPI: string = this.baseUrl + '/imageupload/surgeons';
    private getICDetailsAPI: string = this.baseUrl + '/user/imagingcenter';
    private auditlogsAPI: string = this.baseUrl + '/manualuploader/audit';
    private fetchAuditlogsAPI: string = this.baseUrl + '/imageupload/fetchauditlog';
    private auditLogFilterAPI: string = this.baseUrl + '/imageupload/auditlogfiltervalues';
    private exportAuditlogsAPI: string = this.baseUrl + '/imageupload/exportlog';
    private loggedInDetailsAPI: string = this.baseUrl + '/imageupload/loggedinuserdetails';
    private getApplicationAPI: string = this.baseUrl + '/imageupload/applications';
    private getSkippedFilesAPI: string = this.baseUrl + '/imageupload/skippedfile';
    private UploadStatusAPI: string = this.baseUrl + '/imageupload/fileupload';
    private createBatchAPI: string = this.baseUrl + '/imageupload/batch';
    private postSkippedFileAPI: string = this.baseUrl + '/imageupload/skippedfile';
    private putErrorDetailsAPI: string = this.baseUrl + '/imageupload/acknowledge';
    private putRecallFileAPI: string = this.baseUrl + '/imageupload/recall';
    private flattenedFileAPI: string = this.baseUrl + '/imageupload/groupbypatient';
    private fileDashboardAPI: string = this.baseUrl + '/imageupload/dashboard';
    private dashboardHistoryFilterAPI: string = this.baseUrl + '/imageupload/filtervalues';
    private settingsDetailsAPI: string = this.baseUrl + '/imageupload/viewconfig';
    private getFacilityLinkingAPI: string = this.baseUrl + '/imageupload/facilitylinking';    
    private historyDetailsAPI: string = this.baseUrl + '/imageupload/history';
    private getViewHistoryAPI: string = this.baseUrl + '/imageupload/details';
    private postFacilityLinkingAPI: string = this.baseUrl + '/imageupload/facilitylinking';
    private putFacilityLinkingAPI: string = this.baseUrl + '/imageupload/facilitylinking';
    private putActivationStatusAPI: string = this.baseUrl + '/imageupload/activationstatus';
    private chooseFacilityAPI: string = this.baseUrl + '/imageupload/facilities';
    private auditLogActivityAPI: string = this.baseUrl + '/imageupload/auditlog';
    private auditLogExportAllAPI: string = this.baseUrl + '/imageupload/exportall';
    private getUploadFileStatusAPI: string = this.baseUrl + '/imageupload/getuploadstatus';
    // CLP API URLS
    private facilityClpAPI: string = this.baseUrlClp + '/clp/facilitylist';
    private getFacilityLinkingClpAPI: string = this.baseUrlClp + '/clp/imageupload/facilitylinking';
    private getSurgeonClpAPI: string = this.baseUrlClp + '/clp/allactivesurgeons';
    private getApplicationClpAPI: string = this.baseUrlClp + '/clp/imageupload/applications';
    private loggedInDetailsClpAPI: string = this.baseUrlClp + '/clp/imageupload/loggedinuserdetails';
    private loggedInDetailsClpSnnAPI: string = this.baseUrlClpSnn + '/adminclp/imageupload/loggedinuserdetails';
    private facilityLinkingClpAPI: string = this.baseUrlClp + '/clp/facilitylinking';
    private putActivationStatusClpAPI: string = this.baseUrlClp + '/clp/activationstatus';
    private putFacilityLinkingCLPAPI: string = this.baseUrlClp + '/clp/facilitylinking';  
    
    //Post updateMissingTags 
    private postUpdateMissingTags: string = this.baseUrlClp + '/imageupload/updateMissingTags';

    //get Batch details
    private batchDetailsAPI: string = this.baseUrl + '/imageupload/batchhistory';
    private getBatchIdDetailsAPI: string = this.baseUrl + '/imageupload/batchdetails';

    //get batch history filter values
    private batchHistoryFilterAPI: string = this.baseUrl + '/imageupload/batchfiltervalues';
    
    private httpOptions: any;
    httpHeaders: any;
    auditAction: any;
    userInfo: any;
    errMsg: string;
    errUserDetails: any;
    actionActivityDetails: any;
    actionOutcome: any;
    tokenGroup: string;
    baseEnv: environmentURL;

    constructor(
        private http: HttpClient,
        private sharedState: SharedStateService,
    ) {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        //To get user actions from json file
        this.sharedState.userActionStateData$.subscribe(response => {
        this.auditAction = response;
        });
        //Recieving loggedIn user details from local storage 
        if (localStorage.getItem('userDetails')) {
        this.userInfo = JSON.parse(localStorage.getItem('userDetails'));
        }
    }

    fetchTokenCode(code, vId, baseState): Observable<any> {
        if(baseState.toLowerCase() == constantStrings.snnPortal.toLowerCase()){
           this.baseEnv = environment.snnClp
        } else {
            this.baseEnv = environment.clp
        }
        const params = `grant_type=authorization_code&client_id=${this.baseEnv.userPoolWebClientId}&code=${code}&code_verifier=${vId}&redirect_uri=${environment.duuCloudFrontSigninUrl}`;
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this.http.post(this.baseEnv.hostedUIUrl + 'token', params, {headers}).pipe(catchError(this.handleError));
    }    

    public getFacility(): any {
        return this.http.get(this.getFacilityAPI
            ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.FACILITY))));
    }

    //Function returning Get API Url for Surgeon
    public getSurgeon(facilityId):any{
        let queryParams = new HttpParams();
        queryParams = queryParams.append("facilityid",facilityId);
        return this.http.get(
            this.getSurgeonClpAPI,{params:queryParams}
            ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.SURGEON))));
    }

    //Function returning Get API Url for Application
    public getApplication(facilityId, surgeonId):any{
        let queryParams = new HttpParams();
        queryParams = queryParams.append("facilityid",facilityId);
        queryParams = queryParams.append("surgeonid",surgeonId);
        return this.http.get(
            this.getApplicationClpAPI,{params:queryParams}
            ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.APPLICATION))));
    }

    /**
       * @description To get skipped files details 
       * @param {*} batchId
       * @memberof ApiprocedureService
       * @returns skipped files details
       */
    public getSkippedFiles(batchId): any {
        return this.http.get(
            this.getSkippedFilesAPI + '?batchid=' + batchId
            ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.SKIPPEDFILE))));
    }

    private handleError(error: HttpErrorResponse): any {
        let errMsg: string;
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errMsg = 'An error occurred:' + error.error.message;
        } else {
            const statusCode = 400;
            if (error.status === statusCode) {
                errMsg = error.error.errorMessage;
            } else {
                errMsg = constantStrings.APIHANDLEERROR;
            }
        }
        return throwError(errMsg);
    }
    /**
    * @description To get the imaging centre
    * @memberof ApiprocedureService
    * @returns this will call the imaging centre api to get the imaging centre name
    */
    public getICDetails(): Observable<any> {
        return this.http.get(
            this.getICDetailsAPI
            ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.IMAGINGCENTER))));
    }

    /**
* (description): To post the audit log
* (returns) : this will call the user list api to post the audit log
* (memberof) : ApiprocedureService
*/
    public postAuditLogsService(params): Observable<any> {
        return this.http.post(
            this.auditlogsAPI, params
            ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.AUDITLOG))));
    }

        /**
* (description): To post the audit log
* (returns) : this will call the user list api to post the audit log
* (memberof) : ApiprocedureService
*/
public postFetchAuditLogsService(params): Observable<any> {
    return this.http.post(
        this.fetchAuditlogsAPI, params
        ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.FETCH))));
    }

        /**
* (description): To Export the audit log
* (returns) : this will call the user list api to post the audit log
* (memberof) : ApiprocedureService
*/
public postExportAuditLogsService(params): Observable<any> {
    return this.http.post(
        this.exportAuditlogsAPI, params,{ observe: 'body', responseType: 'text' as 'json'}
        ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.EXPORT))));
}


  /**
    * (description): To get filter details
    * (parameter) : facilityId
    * (memberof) : ApiprocedureService
    */
   public auditLogFilterApi(facilityId): any {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("userFacilityId",facilityId);
    return this.http.get(this.auditLogFilterAPI,{params:queryParams}) 
    .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.FILTER))));
}
        /**
* (description): To post the fileDashboard
* (returns) : this will call the user list api to post the filedashboard
* (memberof) : ApiprocedureService
*/
public postFileDashboard(params): Observable<any> {
    return this.http.post(
        this.fileDashboardAPI, params)
        .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.DASHBOARD))));
    }

    /**
    * (description): To get loggedIn user details
    * (parameter) : userName
    * (memberof) : ApiprocedureService
    */
    public getLoggedInDetails(userName, group): any {
        this.tokenGroup = group;
        let queryParams = new HttpParams();
        if (this.tokenGroup == ROLE.SNN_HIL_MANAGER) {
            queryParams = queryParams.append("username",userName);    
            return this.http.get(this.loggedInDetailsClpSnnAPI,{params:queryParams})
            .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.LOGIN))));        
        } else {
           // const userId = userName ? userName.substring(5): ''
            queryParams = queryParams.append("username",userName);    
            return this.http.get(this.loggedInDetailsClpAPI,{params:queryParams})
            .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.LOGIN))));        
        }
    }

    /**
    * (description): To get upload file status
    * (parameter) : actualFile, batchId
    * (memberof) : ApiprocedureService
    */
    public getUploadStatusAPI(params): Observable<any> {
        const req = new HttpRequest('POST', this.UploadStatusAPI, params, {
            reportProgress: true,
          });
        let stopUpload: boolean = false;
        let uploadFileStorage: any = localStorage.getItem('uploadFileStatus');
          for(let statusFile of uploadFileStorage){
            if((statusFile.batchId == params.batchId) && (statusFile.fileStatus == constantStrings.FAILED)){
                stopUpload = true;
                break;
            } else {
                continue;
            }
          }
          if(!stopUpload){
            return this.http.request<any>(req).pipe(
                map((res: any) => {
                  return res;
                }),
                catchError(errorEvent => {
                    return throwError(errorEvent);
                }));
          } 
          return throwError('File upload error');
    }

    /**
    * (description): To Recall uploaded file
    * (parameter) : uploadId , batchId
    * (memberof) : ApiprocedureService
    */
    public recallUploadAPI(params): any {
        return this.http.put(this.putRecallFileAPI, params)
            .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.RECALL))));
    }
    
    
        /**
        * (description): To send details of skipped files
        * (parameter) : fileName, filePath, batchId
        * (memberof) : ApiprocedureService
        */
        public skippedFile(params): any {
            return this.http.post(this.postSkippedFileAPI, params)
                .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.SKIPPEDFILE))));
            }

           /**

        * (description): To send batchId to flattned file
        * (parameter) : batchId
        * (memberof) : ApiprocedureService
        */
            public flattenedFilesAPI(params): any {
                return this.http.post(this.flattenedFileAPI, params)
                    .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.GROUPBY))));
                }

    /**
    * (description): To send details of read unread errors in dashboard
    * (parameter) : uploadId, isRead
    * (memberof) : ApiprocedureService
    */
    public acknowledgeErrorDetails(params): any {
        return this.http.put(this.putErrorDetailsAPI, params)
            .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.ACKNOWLEDGE))));
    }

    /**
    * (description): To get filter details
    * (parameter) : facilityId
    * (memberof) : ApiprocedureService
    */
     public dashboardHistoryFilter(facilityId): any {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("sourceFacilityId",facilityId);
        return this.http.get(this.dashboardHistoryFilterAPI,{params:queryParams})
        .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.FILTER))));
    }

    /**
    * (description): To get settings details
    * (parameter) : facilityId
    * (memberof) : ApiprocedureService
    */
     public getSettingsDetails(facilityId): any {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userFacilityId",facilityId);
        return this.http.get(this.settingsDetailsAPI,{params:queryParams})
        .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.SETTINGS))));
    }

    /**
    * (description): To send details settings details
    * (parameter) : facilityId, successDays, errorDays
    * (memberof) : ApiprocedureService
    */
    public putSettingsDetails(params): any {
        return this.http.put(this.settingsDetailsAPI, params)
            .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.SETTINGS))));
        }
    
    public getFacilities(facilityType): any {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("facilityType",facilityType);
        return this.http
        .get(this.facilityClpAPI,{params:queryParams})
        .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.FACILITY))));
    }
    
    /**
    * (description): To get facility details
    * (parameter) : facilityId
    * (memberof) : ApiprocedureService
    */
     public getFacilityLinkingDetails(queryParams): any {
        return this.http.get(this.getFacilityLinkingClpAPI ,{params:queryParams})
        .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.IMAGING))));
    }
    /**
     * (description): To post facility details for create link
     * (parameter) : sourceFacilityId, targetFacilityId
     * (memberof) : ApiprocedureService
     */
     public postFacilityLinkingDetails(params): any {
        return this.http
          .post(this.facilityLinkingClpAPI, params)
          .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.IMAGING))));
      }
      /**
       * (description): To put facility details for remove link
       * (parameter) : sourceFacilityId, targetFacilityId
       * (memberof) : ApiprocedureService
       */
      public putFacilityLinkingDetails(params): any {
        return this.http
          .put(this.facilityLinkingClpAPI, params)
          .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.IMAGING))));
        }
      /**
       * (description): To update Activate/deactivate Site
       * (parameter) : activationStatus, facilityId
       * (memberof) : ApiprocedureService
       */
      public putActivationStatus(params): any {
        return this.http
          .put(this.putActivationStatusClpAPI, params)
          .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.ACTIVATION))));
      }
      /**
    * (description): To post the create link details
    * (paramater) : sourceFacilityId, targetFacilityId
    * (memberof) : ApiprocedureService
    */
    public postCreatLinkDetails(params): Observable<any> {
        return this.http.post(this.putFacilityLinkingCLPAPI, params
            ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.CREATELINK))));
        }
     /**
    * (description): To put the remove link details
    * (paramater) : sourceFacilityId, targetFacilityId
    * (memberof) : ApiprocedureService
    */
      public putRemoveLinkDetails(params): Observable<any> {
        return this.http.put(this.putFacilityLinkingCLPAPI, params
            ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.REMOVELINK))));
        }
     /**
    * (description): To post the history details
    * (returns) : this will call the user list api to post the history details
    * (memberof) : ApiprocedureService
    */
      public historyDetails(params): Observable<any> {
        return this.http.post(
            this.historyDetailsAPI, params
        ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.HISTORY))));
    }

    /**
    * (description): To get history view details
    * (parameter) : uploadId
    * (memberof) : ApiprocedureService
    */
     public getViewHistoryDetails(uploadId): any {
        return this.http.get(this.getViewHistoryAPI + '?uploadId=' + uploadId
        ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.HISTORYDETAILS))));
    }
    /**
    * (description): To get facilities details
    * (parameter) : facilitytype
    * (memberof) : ApiprocedureService
    */
     public chooseFacility(facilityType): any {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("facilityType",facilityType);
        return this.http
        .get(this.facilityClpAPI,{params:queryParams})
        .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.FACILITY))));
    }
    /**
    * (description): To post user activity details
    * (parameter) : action params
    * (memberof) : ApiprocedureService
    */
     public auditLogActivity(params): any {
        return this.http.post(this.auditLogActivityAPI , params)
        .pipe(catchError(this.handleError));
    }
        /**
    * (description): To get audit export all details
    * (parameter) : facilitytype
    * (memberof) : ApiprocedureService
    */
     public getAuditExportAll(userFacilityId): any {
        return this.http.get(this.auditLogExportAllAPI + '?userFacilityId=' + userFacilityId
        ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.EXPORTALL))));
    }
    /**
    * (description): To post audit export all details
    * (parameter) : action params
    * (memberof) : ApiprocedureService
    */
     public postAuditExportAll(params): any {
        return this.http.post(this.auditLogExportAllAPI + '?userFacilityId=' + params,{ observe: 'body', responseType: 'text' as 'json'}
        ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.EXPORTALL))));
    }

    /**
     * (description): To call audit log activity function
     * (memberof) : component
    **/
    auditLogActivityStore(error, name){
        if (error.error instanceof ErrorEvent) {
            this.errMsg = 'An error occurred:' + error.error.message;
        } else {
            const statusCode = 400;
            if (error.status === statusCode) {
                this.errMsg = error.error.errors[0];
            } else {
                this.errMsg = constantStrings.APIHANDLEERROR;
            }
        }
        if(this.userInfo){
            this.actionOutcome = {
                "userFacilityId" : this.userInfo.userFacilityId,
                "userName" : this.userInfo.userName,
                "action" : this.auditAction.UserActions.sysException + name + " " + this.errMsg,
                "eventOutCome" : 0
            }    
            this.auditLogActivity(this.actionOutcome).subscribe({
                next: result => { 
                    this.actionActivityDetails = result;
                }, error: err => {
                    this.errUserDetails = err;
                }
            });
        }
        return throwError(this.errMsg);

    }

    /**
     * (description) post updating missing tags
     * (memberof) : ApiprocedureService
     */
     public postUpdateMissingTag(params): Observable<any> {
        return this.http.post(
            this.postUpdateMissingTags, params,{responseType: 'text'}
        );
    }

     /**
    * (description): To get upload file status
    * (memberof) : ApiprocedureService
    */
      public getUploadFileStatus(params): any {
        return this.http.post(this.getUploadFileStatusAPI,params)
        .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.UPLOADSTATUS))));
    }

      /**
    * (description): To get the batch details
    * (returns) : this will call the user list api to get the batch details
    * (memberof) : ApiprocedureService
    */
       public getBatchDetails(params): Observable<any> {
        return this.http.post(
            this.batchDetailsAPI, params
        ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.HISTORY))));
    }

    /**
    * (description): To get batch id details
    * (parameter) : uploadId
    * (memberof) : ApiprocedureService
    */
     public getBatchIdDetails(batchId): any {
        return this.http.get(this.getBatchIdDetailsAPI + '?batchId=' + batchId
        ).pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.BATCHIDDETAILS))));
    }

    /**
    * (description): To get batch filter details
    * (parameter) : facilityId
    * (memberof) : ApiprocedureService
    */
     public batchHistoryFilter(facilityId): any {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("sourceFacilityId",facilityId);
        return this.http.get(this.batchHistoryFilterAPI,{params:queryParams})
        .pipe(catchError((err) => (this.auditLogActivityStore(err, APINames.FILTER))));
    }
}
