import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from '../usermanagement.service';
import { InfoAlertService } from '../../shared/services/infoalert.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/reducers/app.reducers';
import * as AuthActions from '../store/auth.actions';
import { ResendCodeInfo, INITIAL_RESENDCODE_STATE, settingsResponse, environmentURL } from '../../shared/models/user.model';
import { bool } from 'aws-sdk/clients/signer';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadService } from 'src/app/uploadfiles/upload.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { constantStrings, ROLE } from 'src/app/shared/helpers/constants';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { environment } from 'src/environments/environment';
import { LoggedInUserDetails } from 'src/app/shared/models/login.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private userName: string;
  private password: string;
  private loginLocalized: any;
  public isSubmitted: bool = false;
  public errorMessage: string;
  private resendCodeInfo: ResendCodeInfo;
  public mfaStep: boolean = false;
  public showButton: bool = false;
  public showEye: bool = false;
  public errorFromConfirm: boolean;
  showVerify: boolean = false;
  showConfirm: boolean = true;
  public resendCode: boolean = false;
  public userLoginErrRes: any;

  public mfaData = {
    destination: '',
    callback: null
  };
  loginRes: any;
  stateName: Promise<void>;
  errUserDetails: any;
  userDetails: string;
  role: any;
  imageurl: any;
  userstorage: any;
  actionActivityDetails: settingsResponse;
  userInfo: any;
  auditAction: any;
  clpLoginUrl = '';
  openIDCode: string;
  userLoginRes: any;
  decodedToken: string;
  cognitoGroup: any;
  baseState: string = '';
  baseEnv: environmentURL;
  loggeduserInfo: LoggedInUserDetails;
  /**
   * (description): To initialize the required service for the component creates the login form when then object is instantiated
   * (memberof) : LoginComponent
   */
  constructor(
    private router: Router,
    private alertService: InfoAlertService,
    private translateService: TranslateService,
    private userService: UserManagementService,
    private store: Store<fromApp.AppState>,
    private uploadService: UploadService,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private sharedState: SharedStateService,
    private activatedRoute: ActivatedRoute
  ) {
    this.openIDCode = this.activatedRoute.snapshot.queryParamMap.get('code');
    localStorage.setItem('tokenCode', JSON.stringify(this.openIDCode));
    let basePortal = this.activatedRoute.snapshot.queryParamMap.get('state');
    this.baseState = basePortal ? basePortal : constantStrings.otherPortal;
    if(this.baseState.toLowerCase() == constantStrings.snnPortal.toLowerCase()){
      this.baseEnv = environment.snnClp
    } else {
      this.baseEnv = environment.clp
    }
    this.password = '';
    this.userName = '';
    this.errorMessage = '';
    translateService.setDefaultLang('en');
    this.resendCodeInfo = INITIAL_RESENDCODE_STATE;
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
  }

  ngOnInit(): void {
    this.translateService.get(['loginLocals'])
      .subscribe((data) => {
        this.loginLocalized = data.loginLocals;
      });
      //To clear the local Storage
      this.authService.clear();
      //Condition used to call token method if CLP code is present else user will logout
      if (this.openIDCode) {
        this.getTokenCode();
      } else {
        this.authService.logout();
      }
  }
  /**
   * (description): function call to get token from clp code
   * (memberof) : Component
  **/
  public getTokenCode() {
    this.spinnerService.show();
    if(this.openIDCode){
        this.authService.getVisitorId().then(
        response => {
          if (response) {
            this.userService.fetchTokenCode(this.openIDCode, response, this.baseState, (err: any, result: any) => {
              if (err) {
                this.userLoginErrRes = err;
                this.tokenFailure();
              }
              else {
                this.userLoginRes = result;
                if(result && result.id_token){
                  let session = {};
                  session['idToken'] = this.userLoginRes.id_token;
                  session['accessToken'] = this.userLoginRes.access_token;
                  session['refresh_token'] = this.userLoginRes.refresh_token;
                  session['surgeonId'] = this.userLoginRes.userName;
                  localStorage.setItem('clpSession', JSON.stringify(session));
                  this.integrationLogin();
                } else {
                  this.tokenFailure();
                }
              }
            });
          }
        }
      );
    } 
    else {
      this.authService.logout();
    }
  }

  /**
   * (description): function call on token failure
   * (memberof) : Component
  **/
  tokenFailure(){
    //To clear the local Storage
    this.authService.clear();
    let cloudfrontCLPURL = this.baseEnv.cloudFrontCLPSignInUrl;
    cloudfrontCLPURL = cloudfrontCLPURL.replace(/signin/g, 'application/product');
    window.location.href = cloudfrontCLPURL;
  }

  /**
   * (description): function call token success
   * (memberof) : Component
  **/
  async integrationLogin() {
    let idToken = this.authService.getCognitoIdTokenFromStorage();
    this.decodedToken = this.authService.decode(idToken);
    if(this.decodedToken['okta:olduserId']){
      this.userName = this.decodedToken['okta:olduserId'];
    }else{
      this.userName = this.decodedToken['cognito:username'];  
    }
    this.cognitoGroup = this.decodedToken['cognito:groups'];
    this.authService.getSSMValue(environment.ssmParams.IDLETIME);
    this.getLoggedInUser(this.userName);
  }

  /**
   * (description): To call remove confirm error function
   * (memberof) : Component
  **/
  public removeConfirmError() {
    if (this.errorFromConfirm) {
      this.store.dispatch(new AuthActions.SendConfirmError(undefined));
    }
  }
  /**
   * (description): To call loggedIn user details API call
   * (memberof) : Component
  **/
  public getLoggedInUser(UserName): any {
    this.spinnerService.show();
    this.uploadService.getLoggedInDetails(UserName, this.cognitoGroup[0]).subscribe({
      next:result => {
        this.userInfo = result;
        this.userDetails = result.userFirstName?.slice(0, 1) + ' ' +result.userLastName;
        this.role = result.userRole;
        this.imageurl = result.profilePic;
        this.userstorage = result.userName;
        this.loggeduserInfo = {
          userName: result.userName,
          userRole: result.userRole,
          userFirstName: result.userFirstName,
          userLastName: result.userLastName,
          userEmail: result.userEmail,
          userFacilityId: result.userFacilityId,
          userFacilityName: result.userFacilityName,
          userFacilityContactLead: result.userFacilityContactLead,
          userFacilityEmail: result.userFacilityEmail,
          userFacilityPhone: result.userFacilityPhone,
          userFacilityState: result.userFacilityState,
          userFacilityZipCode: result.userFacilityZipCode,
          userFacilityAddress: result.userFacilityAddress,
          userFacilityCity: result.userFacilityCity,
        }
        this.authService.setItem('userDetails', JSON.stringify(this.loggeduserInfo));   
        this.sharedState.setLoginStateData(result);     
        let dt = Date.now();
        sessionStorage.setItem('isSessionActive',""+dt);
        this.auditLogActivity();
        if(this.role.toLowerCase() == ROLE.SNN_HIL_MANAGER){
          this.sharedState.snnFacilityDetails(null);
          this.router.navigate(['/choosesite']);
        } else {
          this.router.navigate(['/filedashboard']);
        }
      }, error: err => {
        this.errUserDetails = err;
        this.auditLogActivity();
        this.spinnerService.hide();
      }
    });

  }

  /**
   * (description): To call audit log activity function
   * (memberof) : component
  **/
  auditLogActivity() {
    if (this.userInfo) {
      let action = {
        "userFacilityId": this.userInfo.userFacilityId,
        "userName": this.userInfo.userName,
        "action": this.auditAction.UserActions.loginLaunched + this.auditAction.UserActions.loginAction,
        "eventOutCome": this.userInfo ? 1 : 0
      }
      this.uploadService.auditLogActivity(action).subscribe({
        next: result => {
          this.actionActivityDetails = result;
        }, error: err => {
          this.errUserDetails = err;
        }
      });
    }
  }

}
