import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantStrings } from '../shared/helpers/constants';
import { AddSurgeonModal, INITIAL_ADDSURGEON_MODAL_STATE, INITIAL_MODAL_STATE, Modal } from '../shared/models/dialog.model';
import { AuthService } from '../shared/services/auth.service';
import { SharedStateService } from '../shared/services/shared-state.service';
import { ImageUploadComponent } from '../uploadfiles/image-upload/image-upload.component';
import { UploadService } from '../uploadfiles/upload.service';

@Component({
  selector: 'app-upload-success',
  templateUrl: './uploadsuccess.component.html',
  styleUrls: ['./uploadsuccess.component.scss']
})
export class UploadSuccessComponent implements OnInit {

  @Input() modal: Modal = INITIAL_MODAL_STATE;
  public addModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;
  @ViewChild(ImageUploadComponent) FacilityModel;
  public showSuccessModal: boolean;
  public value1 = true;
  @Output() event = new EventEmitter<boolean>()
  batchId: any;
  totalFiles: number;
  id: string;
  receiveValue($event){
    this.showSuccessModal=$event;
  }
  totalFilesCount:number;
  skippedFilesCount:number = 0;
  constructor(public router: Router,
    private sharedState: SharedStateService,
    private uploadService:UploadService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private actRouter: ActivatedRoute
    ) 
    {
      this.actRouter.queryParams
      .subscribe(params => {
        this.id = params.batchId;
      });
    this.sharedState.dicomStateData$.subscribe(response => {
      this.batchId = response.batchId;
      this.totalFiles= response.totalFileCount;
      if(this.id){
        this.getSkippedFilesDetails(this.id);
      }else if(this.batchId){
        this.getSkippedFilesDetails(this.batchId);
      } 
    });
   }

  ngOnInit(): void {
  }

  /**
   * @description To get Skipped Files API response 
   * @param {*} batchId
   * @memberof UploadSuccessComponent
   */
  public getSkippedFilesDetails(batchId): any {
    let statusFile = this.authService.getItem('uploadFileStatus');
    if(statusFile){
      statusFile = statusFile[0];
      // for(let statusFile of uploadFileStorage){
        if(statusFile.batchId == batchId){
          this.totalFilesCount= statusFile.expectedFileCount;
          this.skippedFilesCount = statusFile.skippedFileCount;
        }
      // }
    }
    this.showSuccessModal = true;
  }
  /**
   * @description To open facility modal 
   * @param {*} batchId
   * @memberof UploadSuccessComponent
   */
  public openFacilityModal(): void {
    let notification = {
      displayUploadFile: false,
      displaySiteModal: true,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.router.navigate(
      ['/uploadfiles'],       //nativate to upload files page
      { queryParams: { id: constantStrings.newUpload } }          //unique id passed 'newUpload' along with path
    );

  }
  /**
   * @description To open success modal 
   * @param {*} batchId
   * @memberof UploadSuccessComponent
   */
  public closeSuccessModal(): void {
    let uploadFileStorage = this.authService.getItem('uploadFileStatus');
    if(uploadFileStorage){
      for(let statusFile of uploadFileStorage){
        if((statusFile.batchId == this.batchId) && (statusFile.baseFile == constantStrings.UPLOADFILE)){
          let notification = {
            displayUploadFile: true,
            displaySiteModal: false,
            displaySurgeonModal: false,
            displayApplicationModal: false,
            displaySelectFilesModal: false
          }
          this.sharedState.popupCancelData(notification);
          this.router.navigate(
            ['/uploadfiles'],       //nativate to upload files page
            { queryParams: { id: constantStrings.newUpload } }          //unique id passed 'newUpload' along with path
          );
        }else if((statusFile.batchId == this.id) && (statusFile.fileStatus == constantStrings.UPLOADED)){
          this.router.navigate(['/uploadfiles']);
        }else {
          this.event.emit(this.value1);
          this.modal = INITIAL_MODAL_STATE;
          this.modal.display = 'none';
        }
      }  
    }    
  }

}
